import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Provider } from "@rollbar/react";
import { device, firebaseConfig, isCapacitor, rollbarConfig } from "./config";
import { isPlatform } from "@ionic/react";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import GlobalContext from "./contexts/GlobalContext";
import Loading from "./pages/Loading";
import { Auth0Context } from "./components/auth0";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { cookiesConsetDefaults } from "./constants/cookies";

defineCustomElements(window);

function set_gs_env() {
  FirebaseAnalytics.setUserProperty({
    name: "environment",
    value: process.env.NODE_ENV,
  });
}

const TrackedApp: React.FC = () => {
  const [isAnalLoading, setIsAnalLoading] = useState(
    device === "desktop" ? false : true
  );
  const [analCookiesAllowed, setAnalCookiesAllowed] = useState(false);

  useEffect(() => {
    if (device === "desktop" && analCookiesAllowed === false) return;

    if ((isPlatform("desktop") || isPlatform("mobileweb")) && isCapacitor) {
      console.log("init ga");
      FirebaseAnalytics.initializeFirebase(firebaseConfig)
        .then(() => {
          set_gs_env();
          setIsAnalLoading(false);
        })
        .catch(() => {
          setIsAnalLoading(false);
        });
    } else {
      if (isCapacitor) set_gs_env();
      setIsAnalLoading(false);
    }
  }, [analCookiesAllowed]);

  useEffect(() => {
    if (device === "desktop")
      CookieConsent.run({
        ...cookiesConsetDefaults,
        onConsent: ({ cookie }) => {
          setAnalCookiesAllowed("analytics" in cookie.services);
        },
      });
  }, []);

  return (
    <Provider config={rollbarConfig}>
      <Auth0Context>
        <GlobalContext>{isAnalLoading ? <Loading /> : <App />}</GlobalContext>
      </Auth0Context>
    </Provider>
  );
};

if (window.location.pathname.startsWith("/events")) {
  window.location.replace(
    window.location.href.replace("/events/", "#/events/")
  );
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<TrackedApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
